import jwtDecode from 'jwt-decode';
import store from '@/store/index';

export default function adminRole() {
  const { getters } = store;
  const accessToken = getters['dashboard/getAccessToken'];
  let decodedToken;

  try {
    decodedToken = jwtDecode(accessToken);
  } catch (e) {
    decodedToken = {};
  }

  const { role } = decodedToken;

  return role || 'invalid';
}
