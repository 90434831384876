<template>
  <div class="tag__container">
    <div class="tag" :class="(icon, { 'has-title': title })">
      <span class="tag__title" v-if="title"> {{ title }}</span>
      <span class="tag__group">
        <component v-if="icon" :is="icon" class="tag__icon" />
        <span
          v-if="typeof tag === 'string'"
          :class="{ margin: icon, tag__value: title }"
          >{{ tag }}</span
        >
        <span v-else :class="{ margin: icon, tag__value: title }">
          {{ tag[displayLabel] }}
        </span>
      </span>
      <div class="icon-container" v-if="allowDelete && !title">
        <close-circle-icon class="tag__delete-icon" @click="deleteTag" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tag: {
      type: [String, Object, Number],
      required: true,
    },
    icon: {
      type: String,
    },
    customIconClass: {
      type: String,
    },
    displayLabel: {
      type: String,
    },
    allowDelete: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    customClass: {
      type: [String, Object, Array],
    },
  },
  methods: {
    deleteTag() {
      this.$emit('delete-tag', this.tag);
    },
  },

};
</script>

<style scoped lang="scss">
@import "~styles/shared/tags.scss";
</style>
