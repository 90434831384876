<template>
  <span class="autocomplete-container" v-click-outside="closeList">
    <hcc-input
      v-model="search"
      @input="searching"
      @focus="openList"
      :disabled="noOptions ? true : false"
      :class="{ 'full-width': fullWidth }"
      :custom-class="{ selected: selectedOption }"
      v-bind="$attrs"
    >
      <template #icon v-if="selectedOption"
        ><plus-icon @click="addItem" class="autocomplete__icon"
      /></template>
    </hcc-input>
    <div class="autocomplete" v-if="showList">
      <ul
        v-for="option in listOptions"
        :key="label ? option[label] : option"
        class="autocomplete__list"
      >
        <li class="autocomplete__item" @click="selectOption(option)">
          {{ option[label] }}
        </li>
      </ul>
    </div>
  </span>
</template>

<script>
import vClickOutside from 'v-click-outside';
import HccInput from '../HccInput/index.vue';

export default {
  components: {
    HccInput,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    displayLabel: {
      type: String,
      default: null,
    },
    filterOptions: {
      type: Function,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: '',
      showList: false,
      selectedOption: null,
      listOptions: [],
    };
  },
  computed: {
    noOptions() {
      return this.options.length === 0;
    },
  },
  methods: {
    searching(input) {
      if (input.length < 1) {
        this.listOptions = [];
      } else if (!this.filterOptions) {
        this.listOptions = this.options.filter(option => option[this.label].toLowerCase()
          .includes(input.toLowerCase()));
      } else if (this.options.every(option => (typeof option === 'string'))) {
        this.listOptions = this.options.filter(option => option.toLowerCase()
          .includes(input.toLowerCase()));
      } else {
        this.listOptions = this.options.filterOptions();
      }
      this.selectedOption = null;
      this.showList = this.listOptions.length > 0;
    },
    openList() {
      if (this.listOptions.length > 0) {
        this.showList = true;
      }
    },
    closeList() {
      this.showList = false;
    },
    selectOption(option) {
      if (!this.displayLabel) {
        this.search = option[this.label];
      } else {
        this.search = option[this.displayLabel];
      }
      this.selectedOption = option;
      this.closeList();
    },
    addItem() {
      this.$emit('add-item', this.selectedOption);
      this.search = '';
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/autocomplete.scss";
</style>
