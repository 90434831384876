<template>
  <div class="loader-container">
    <overlay-loader :loading="pending" />
    <hcc-table
      :columns="columns"
      :rows="admins"
      :title="tableName"
      :buttonText="assign"
      @search="searchUser"
      :enableAdd="canAssign"
      @add="assignAdmin"
      @delete="deleteAdmin"
      @pageChanged="changePage"
      :rowsPerPage="pagination.perPage"
      :actualPage="pagination.page"
      :totalPages="pagination.totalPages"
    />
    <assign-admin-modal
      v-if="canAssign"
      :campaign="campaign"
      @updateAdmins="updateAdmins"
    />
    <hcc-confirmation />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import HccTable from '@/components/shared/HccTable/index.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import UsersByCampaignGql from '@/graphql/queries/users/usersByCampaign.gql';
import adminRole from '@/utils/adminRole';
import executeQuery from '@/utils/gql-api';
import unassignUser from '@/graphql/mutations/users/unassignUser.gql';
import errorHandler from '@/utils/error.handler';
import AssignAdminModal from './AssignAdminModal.vue';

export default {
  components: {
    HccTable,
    HccConfirmation,
    OverlayLoader,
    AssignAdminModal,
  },
  data() {
    return {
      text: null,
      adminsByCampaign: [],
      searchAdmin: null,
      admin: {},
      pending: null,
      pagination: {
        page: 1,
        perPage: 10,
        totalPages: 0,
      },
    };
  },
  watch: {
    campaign(newValue, oldValue) {
      if ((newValue.id !== oldValue.id && this.$route.name === 'campaigns-settings')
        || (newValue.id === oldValue.id && newValue.company)) {
        this.fetchAdmins(1);
      }
    },
  },
  computed: {
    ...mapState({
      campaign: state => state.setting.currentCampaign,
    }),
    tableName() {
      return this.$t('campaign-settings.assigned-users.admins');
    },
    assign() {
      return this.$t('campaign-settings.assigned-users.assign');
    },
    delete() {
      return this.$t('campaign-settings.assigned-users.delete-admin');
    },
    admins() {
      return this.adminsByCampaign || [];
    },
    role() {
      return adminRole();
    },
    canAssign() {
      if (this.role === 'owner' || this.role === 'admin' || this.role === 'super') { return true; }
      return false;
    },
    columns() {
      return [{
        label: this.$t('campaign-settings.assigned-users.user'),
        field: 'name',
      }, {
        label: this.$t('campaign-settings.assigned-users.email'),
        field: 'email',
      }];
    },
    emptyRows() {
      return this.pagination.totalPages === 0;
    },
  },
  methods: {
    ...mapActions('setting', ['updateCampaign']),
    async fetchAdmins(page) {
      this.pending = true;
      const result = await executeQuery('usersByCampaign',
        UsersByCampaignGql,
        {
          role: 'admin',
          id: this.campaign.id,
          length: this.pagination.perPage,
          page,
          search: this.text,
        },
        false);

      this.pending = false;
      this.adminsByCampaign = result.data;
      this.pagination.totalPages = result.total;
    },
    assignAdmin() {
      this.$modal.show('assign-admin-modal');
    },
    async updateAdmins() {
      await this.fetchAdmins(1);
      this.updateCampaign({ ...this.campaign, admins: this.pagination.totalPages });
    },
    deleteAdmin(row) {
      const info = {
        admin: row.name,
        campaign: this.campaign.name,
      };

      this.$modal.show('confirmation', {
        title: this.delete,
        description: this.$t('campaign-settings.assigned-users.confirm-delete-admin',
          info),
        variant: 'error',
        confirm: () => this.unassignAdmin(row.id, info),
      });
    },
    async unassignAdmin(admin, info) {
      await this.$apollo.mutate({
        mutation: unassignUser,
        variables: {
          user: admin,
          campaign: this.campaign.id,
        },
      })
        .then(() => {
          this.$toasted.global.success(this.$t('campaign-settings.assigned-users.admin-deleted', info));
          this.updateAdmins();
        })
        .catch((err) => {
          errorHandler.logErrors(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        });
    },
    changePage(newPage) {
      if (this.pagination.page !== newPage) {
        this.pagination.page = newPage;
        this.fetchAdmins(this.pagination.page);
      }
    },
    searchUser({ value, key }) {
      if (key && value.length >= 3) {
        this.text = value;
        this.fetchAdmins(1);
      } else if (value.length === 0) {
        this.text = null;
        this.fetchAdmins(1);
      }
    },
  },
  created() {
    if (this.campaign.id) {
      this.fetchAdmins(1);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/settings/_users-tab.scss";
</style>
