<template>
  <hcc-modal
    name="assign-agent-modal"
    :title="modalName"
    :width="800"
    :confirmDisableButton="selectedAgents.length === 0"
    @confirm="handleConfirm"
    @opened="getAgents"
    @closed="clear"
  >
    <div class="table-container loader-container" @click.stop="() => {}">
      <overlay-loader :loading="pending" />
      <hcc-checkbox-table
        :columns="columns"
        :rows="agents"
        :searchService="true"
        :pagination="pagination.total > 5"
        :rowsPerPage="5"
        :actualPage="pagination.page"
        :totalPages="pagination.total"
        @search="searchUser"
        @pageChanged="changePage"
        @checked="getCheckedAgents"
        @check-all="getCheckedAgents"
      >
      </hcc-checkbox-table>
    </div>
  </hcc-modal>
</template>

<script>
import { fetchUnassignedUsers } from '@/utils/userByCompany';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccCheckboxTable from '@/components/shared/HccTable/HccCheckboxTable.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import assignUsers from '@/graphql/mutations/users/assignUsers.gql';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    HccModal,
    HccCheckboxTable,
    OverlayLoader,
  },
  props: {
    campaign: [Object, null],
  },
  data() {
    return {
      text: null,
      pagination: {
        total: 0,
        page: 1,
        length: 5,
      },
      agents: [],
      selectedAgents: [],
      pending: null,
    };
  },
  computed: {
    modalName() {
      return this.$t('campaign-settings.assigned-users.assign-agent');
    },
    userLabel() {
      return this.$t('campaign-settings.assigned-users.user');
    },
    emailLabel() {
      return this.$t('campaign-settings.assigned-users.email');
    },
    columns() {
      return [{
        label: this.$t('campaign-settings.assigned-users.user'),
        field: 'name',
      }, {
        label: this.$t('campaign-settings.assigned-users.email'),
        field: 'email',
      }, {
        label: this.$t('app.campaigns'),
        field: 'campaigns',
      }];
    },
  },
  methods: {
    getCheckedAgents(rows) {
      this.selectedAgents = rows;
    },
    async getAgents() {
      this.pending = true;

      const result = await fetchUnassignedUsers(
        this.pagination.page,
        this.campaign.company,
        this.campaign.id,
        this.pagination.length,
        this.text,
        'agent',
      );

      this.pagination.total = result.total;
      this.pagination.page = result.page;

      this.agents = result.data;
      this.pending = false;
    },
    handleConfirm() {
      const channels = this.campaign.channels.map(channel => channel.id);

      const agents = this.selectedAgents.map(agent => ({
        user: agent.id,
        campaign: this.campaign.id,
        channels,
      }));

      this.assignAgentToCampaign(agents);
    },
    async assignAgentToCampaign(users) {
      await this.$apollo.mutate({
        mutation: assignUsers,
        variables: { users, role: 'agent' },
      })
        .then(() => {
          this.$emit('updateAgents');
          if (users.length > 1) {
            this.$toasted.global.success(this.$t('campaign-settings.assigned-users.assign-agents-success', { campaign: this.campaign.name }));
          } else {
            this.$toasted.global.success(this.$t('campaign-settings.assigned-users.assign-agent-success', { campaign: this.campaign.name }));
          }
        })
        .catch((err) => {
          errorHandler.logErrors(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        });
    },
    searchUser(value) {
      this.pagination.page = 1;
      if (value.length >= 3) {
        this.text = value;
      } else if (value.length === 0) {
        this.text = null;
      }
      this.getAgents();
    },
    changePage(page) {
      this.pagination.page = page;
      this.getAgents();
    },
    clear() {
      this.text = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/settings/_users-tab.scss";
</style>
