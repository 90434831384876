<template>
  <hcc-modal
    name="edit-agent-modal"
    @confirm="handleConfirm"
    :confirmDisableButton="!hasChannels"
    :title="modalName"
    width="800"
  >
    <div class="users-modal">
      <div class="users-modal__row">
        <div class="users-modal__label">
          {{ userLabel }}
        </div>
        <div class="users-modal__description" v-if="agent">
          {{ agent.name }}
        </div>
      </div>
      <div class="users-modal__row">
        <div class="users-modal__label">
          {{ emailLabel }}
        </div>
        <div class="users-modal__description" v-if="agent">
          {{ agent.email }}
        </div>
      </div>

      <div class="users-modal__row">
        <div class="users-modal__label">
          {{ channelsLabel }}
        </div>
      </div>

      <div class="channels" v-if="agent">
        <div class="autocomplete" v-if="filteredChannels.length > 0">
          <hcc-auto-complete
            :options="filteredChannels"
            label="name"
            :placeholder="addChannelLabel"
            @add-item="addChannel"
          />
        </div>
        <div v-else class="autocomplete-empty">
          {{ noChannels }}
        </div>
        <div class="channels__tags" v-if="agent">
          <div
            v-for="channel in agent.channels"
            :key="channel.id"
            class="channels__item"
          >
            <hcc-tag
              :tag="channel"
              displayLabel="name"
              :icon="channel.channelType.key"
              @delete-tag="deleteChannel"
            />
          </div>
        </div>
      </div>
    </div>
  </hcc-modal>
</template>

<script>
import HccModal from '@/components/shared/HccModal/index.vue';
import HccTag from '@/components/shared/HccTag/index.vue';
import HccAutoComplete from '@/components/shared/HccAutoComplete/index.vue';
import assignUsers from '@/graphql/mutations/users/assignUsers.gql';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    HccModal, HccTag, HccAutoComplete,
  },
  props: {
    agent: [Object, null],
    campaign: [Object, null],
  },
  data() {
    return {};
  },
  computed: {
    modalName() {
      return this.$t('campaign-settings.assigned-users.edit-agent');
    },
    userLabel() {
      return this.$t('campaign-settings.assigned-users.user');
    },
    emailLabel() {
      return this.$t('campaign-settings.assigned-users.email');
    },
    channelsLabel() {
      return this.$t('campaign-settings.assigned-users.channels');
    },
    addChannelLabel() {
      return this.$t('campaign-settings.assigned-users.add-channel');
    },
    noChannels() {
      return this.$t('campaign-settings.assigned-users.no-channels');
    },
    filteredChannels() {
      return this.filterChannels();
    },
    hasChannels() {
      return this.agentChannels.length > 0;
    },
    agentChannels() {
      return this.agent ? this.agent.channels : [];
    },
  },
  methods: {
    handleConfirm() {
      const channelIDs = this.agent.channels.map(channel => channel.id);

      const agent = {
        user: this.agent.id,
        campaign: this.campaign.id,
        channels: channelIDs,
      };

      this.editAgent(agent);
    },
    filterChannels() {
      const channels = this.campaign.channels
        .filter(channel => !this.agent.channels
          .some(agentChannel => agentChannel.name === channel.name));

      return channels;
    },
    async editAgent(agent) {
      await this.$apollo.mutate({
        mutation: assignUsers,
        variables: { users: agent, role: 'agent' },
      })
        .then(() => {
          this.$emit('updateAgents');
          this.$toasted.global.success(this.$t('campaign-settings.assigned-users.edit-agent-success', { agent: this.agent.name }));
        })
        .catch((err) => {
          errorHandler.logErrors(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        });
    },
    addChannel(item) {
      const { channels } = this.agent;
      channels.push(item);
    },
    deleteChannel(item) {
      const { channels } = this.agent;

      const i = channels.findIndex(channel => channel.id === item.id);
      channels.splice(i, 1);
    },

  },
};

</script>

<style lang="scss" scoped>
@import "~styles/components/settings/_users-tab.scss";
</style>
